export const Timer = ({ time }) => {
  const minutes = Math.floor((time / 60000) % 60);
  const seconds = Math.floor((time / 1000) % 60);

  // Format minutes and seconds to have leading zeros
  const formattedMinutes = ("0" + minutes).slice(-2);
  const formattedSeconds = ("0" + seconds).slice(-2);

  return (
    <>
      <span className="me-2">{formattedMinutes}m</span>
      <span>{formattedSeconds}s</span>
    </>
  );
};
