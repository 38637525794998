import i18next from "i18next";

const chapters = [1, 2, 3, 4, 5, 6, 7];
const questions = [1];

export const gamesData = chapters.reduce((previous, current) => {
  return Object.assign(previous, {
    ["game" + current]: {
      id: `game${current}`,
      type: "quiz",
      headline: i18next.t(`S${current}Headline`),
      percentForSuccess: 60,
      numberOfQuestions: 1,
      introText1: i18next.t(`S${current}IntroText1`),
      introText2: i18next.t(`S${current}IntroText2`),
      introTextUSP1: i18next.t(`S${current}IntroTextUSP1`),
      introTextUSP2: i18next.t(`S${current}IntroTextUSP2`),
      introTextUSP3: i18next.t(`S${current}IntroTextUSP3`),
      IntroTextAnswer: i18next.t(`S${current}IntroTextAnswer`, {
        returnObjects: true,
      }),
      failText: i18next.t(`S${current}FailText`),
      successText1: i18next.t(`S${current}SuccessText1`),
      successText2: i18next.t(`S${current}SuccessText2`),
      triviaText1: i18next.t(`S${current}TriviaText1`),
      triviaText2: i18next.t(`S${current}TriviaText2`),
      triviaText3: i18next.t(`S${current}TriviaText3`),
      triviaText4: i18next.t(`S${current}TriviaText4`),
      score: i18next.t(`S${current}Score`),
      step: questions.map((a) => ({
        questionText: i18next.t(`S${current}Q${a}Text`),
        questionImg: i18next.t(`S${current}Q${a}Img`),
        answerIsImg: i18next.t(`S${current}Q${a}AnswerIsImg`),
        questionScore: i18next.t(`S${current}Score`),

        answers: [
          {
            answer: i18next.t(`S${current}Q${a}Answer1`),
            isCorrect: false,
          },
          {
            answer: i18next.t(`S${current}Q${a}Answer2`),
            isCorrect: false,
          },
          {
            answer: i18next.t(`S${current}Q${a}Answer3`),
            isCorrect: true,
          },
        ],
      })),
    },
  });
}, {});
