import { useParams } from "react-router-dom";

import Story1 from "./Story/Story1";
import Story2 from "./Story/Story2";

const Intro = ({ location }) => {
  const { intro } = useParams();

  return (
    <div className="main-container intro theme-gold">
      {intro === "story1" && <Story1 />}
      {intro === "story2" && <Story2 />}
    </div>
  );
};

export default Intro;
