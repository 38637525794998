import { useState, useContext } from "react";
import { VariantContext } from "context/variantContext";
import { useNavigate } from "react-router-dom";

import CurrentGameStatus from "components/CurrentGameStatus/CurrentGameStatus";

import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";

import imgFigure from "assets/images/figure-banka-questions2.svg";

const QuizIntro = () => {
  const [inputValue, setInputValue] = useState("");
  const [isCorrect, setIsCorrect] = useState(null);
  const variant = useContext(VariantContext);

  const navigate = useNavigate();

  // Enable or disable the button based on input value
  const isButtonEnabled = inputValue.trim() !== "";

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setIsCorrect(null);
  };

  const handleClick = () => {
    const normalizedInput = inputValue.toLowerCase();

    if (normalizedInput === "11" || normalizedInput === "enajst") {
      navigate(`/${variant}/menjava`);
      setIsCorrect(true);
    } else {
      setIsCorrect(false);
    }
  };

  return (
    <div className="main-container">
      <Wrapper
        className={`game  intro shadow-wrapper bg-white m-2 w-100 position-relative `}>
        <div className="flex-grow-1 p-2 text-center">
          <CurrentGameStatus />

          <h2 className="text-gold mt-3 mb-2">Blagajna Banke Slovenije</h2>

          <div className="px-3 text-center">
            <p>
              Na Štefanovi ulici 1 ima Banka Slovenije svojo blagajno, kjer
              lahko še vedno zamenjate tolarske bankovce za evre. Tolarski
              kovanci pa so bili zamenljivi do 3. 1. 2017.
            </p>
            <img
              className="w-75"
              src={`/images/lov/intro_blagajna.jpg`}
              alt=""
            />
            <h3 style={{ fontSize: "20px" }} className="text-gold mt-3">
              Ste na pravi lokaciji?
            </h3>
            <p>
              Koliko miz zlate barve se nahaja v celotnem prostoru blagajne
              Banke Slovenije?
            </p>

            <div
              className={`w-100 text-center ${
                isCorrect === false ? "danger" : null
              }`}>
              <input
                className="answer mb-0 form-control text-center"
                type="text"
                name="title"
                placeholder="Vpiši geslo"
                value={inputValue}
                onChange={handleInputChange}
              />
              {isCorrect === false && (
                <p className="text-red fw-bold mb-0 danger">
                  Ta rešitev ni pravilna.
                </p>
              )}

              <Button
                label="NAPREJ"
                className={`w-75 text-center mx-auto mb-0 mt-0 ${
                  !isButtonEnabled ? "disabled" : ""
                }`}
                onClick={handleClick}
                disabled={!isButtonEnabled}
              />
            </div>
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

export default QuizIntro;
