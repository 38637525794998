import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  correctAnswersReducer,
  showResultReducer,
  scoreReducer,
} from "store/gameSlice";

import { useTranslation } from "react-i18next";
import { motion, AnimatePresence } from "framer-motion";
import { blurInOut } from "utils/animations";

// Import styles, media and components
import Wrapper from "components/Wrapper/Wrapper";

import CurrentGameStatus from "components/CurrentGameStatus/CurrentGameStatus";
import QuizAnswers from "./QuizAnswers";
import QuizQuestion from "./QuizQuestion";

const buttonVariants = {
  hidden: {
    y: 50,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      stiffness: 100,
    },
  },
};

function Quiz({ gamesData }) {
  // Shuffle questions
  const [randomizeSteps, setRandomizeSteps] = useState([]);
  // Is current game question correct
  const [isAnswerCorrect, setIsAnswerCorrect] = useState();
  // Set which answer was clicked
  const [answerClicked, setAnswerClicked] = useState(null);
  // Is form submitted
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  // If is loading
  const [isLoadingQuestions, setIsLoadingQuestions] = useState(true);

  const [isFlipped, setFlipped] = useState(false);

  // Total user score

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const currentQuestion = useSelector((state) => state.game.currentQuestion);
  const correctAnswers = useSelector((state) => state.game.correctAnswers);

  const score = useSelector((state) => state.game.score);

  let correctAnswersNum = correctAnswers.filter((x) => x === true).length;
  let totalQuestionsNum = gamesData.numberOfQuestions;
  let successRate = Math.round((correctAnswersNum / totalQuestionsNum) * 100);

  // On answer handler
  const handleAnswerOptionClick = (e, isCorrect) => {
    // set clicked button ID and true/false if answer is correct

    setAnswerClicked(e.currentTarget.id);
    setIsAnswerCorrect(isCorrect);
  };

  // On form submit handler
  const handleSubmit = (e) => {
    e.preventDefault();

    setIsFormSubmitted(true);

    // move to next question
    let nextQuestion = currentQuestion + 1;

    // Find correct answer
    let correctAnswerIndex = randomizeSteps[currentQuestion].answers.findIndex(
      (item) => item.isCorrect === true
    );

    // find correct answer, clicked answer and list all buttons to clear classes on next step
    let answerCorrectBtn = document.getElementById(
      `answer_${correctAnswerIndex}`
    );
    let answerClickedBtn = document.getElementById(answerClicked);
    let answersAllButtons = document.querySelectorAll(".answer-section button");

    console.log(score);
    // add correct or wrong class
    answerClickedBtn.classList.add(isAnswerCorrect ? "correct" : "wrong");

    /*     if (gamesData.id !== "quizbonus") {
      answerCorrectBtn.classList.add(!isAnswerCorrect && "correct");
    } */

    // answerCorrectBtn.classList.add(!isAnswerCorrect && "correct");

    // Add true/false to array and send to global state

    dispatch(correctAnswersReducer(isAnswerCorrect));

    // Reset clicked button state
    setAnswerClicked(null);

    //

    if (nextQuestion < totalQuestionsNum) {
      //dispatch(currentQuestionReducer(nextQuestion));
    } else if (nextQuestion === totalQuestionsNum) {
      if (isAnswerCorrect) {
        dispatch(scoreReducer(randomizeSteps[currentQuestion].questionScore));
      }

      setTimeout(() => {
        setIsFormSubmitted(false);

        // Remove classes for next questoon
        answersAllButtons.forEach(function (element) {
          element.classList.remove("correct");
          element.classList.remove("wrong");
        });

        dispatch(showResultReducer(true));
      }, 1500);
    }

    // What happens after user submits answer
  };

  useEffect(() => {
    setTimeout(() => {
      setFlipped(true);
    }, 1200);

    // Create new array
    const randomizeArray = [];

    // Loop over available steps
    for (var i = 0; i < gamesData.step.length; i++) {
      var item = gamesData.step[i];

      shuffle(item.answers);

      randomizeArray.push(item);
    }

    setRandomizeSteps(randomizeArray);

    setIsLoadingQuestions(false);
  }, []);

  return (
    <Wrapper className={`quiz w-100 ${gamesData.id} text-center`}>
      <div
        className={`position-relative p-3 mb-3 flex-grow-1 d-flex flex-column shadow-wrapper bg-white rounded-2 m-2`}>
        {isLoadingQuestions ? (
          <h1>LOADING</h1>
        ) : (
          <>
            <div>
              <CurrentGameStatus />
            </div>

            <form className="flex-grow-1 mt-2" onSubmit={handleSubmit}>
              <AnimatePresence mode="wait">
                <motion.div
                  className={`h-100 flex-grow-1 d-flex flex-column `}
                  key={`question-${currentQuestion}`}
                  variants={blurInOut}
                  initial="hidden"
                  animate="visible"
                  exit="exit">
                  <QuizQuestion
                    data={randomizeSteps[currentQuestion]}
                    gamesData={gamesData}
                  />
                  <QuizAnswers
                    gamesData={gamesData}
                    data={randomizeSteps[currentQuestion]}
                    clickHandler={handleAnswerOptionClick}
                    isFormSubmitted={isFormSubmitted}
                    isAnswerCorrect={isAnswerCorrect}
                    isFlipped={isFlipped}
                    answerClicked={answerClicked}
                  />

                  <div className="w-100 text-center mt-auto mb-4">
                    {isFlipped && (
                      <motion.button
                        type="submit"
                        className="btn btn-primary btn-confirm fw-bold text-uppercase py-2"
                        disabled={answerClicked === null && "disabled"}
                        // Initial state before the animation begins
                        initial="hidden"
                        // State after the animation completes
                        animate="visible"
                        // Variants object containing the animation details
                        variants={buttonVariants}>
                        POTRDI
                      </motion.button>
                    )}
                  </div>
                </motion.div>
              </AnimatePresence>
            </form>
          </>
        )}
      </div>
    </Wrapper>
  );
}

// Pick random number with no repeats
function randomNoRepeats(array) {
  var copy = array.slice(0);
  return function () {
    if (copy.length < 1) {
      copy = array.slice(0);
    }
    var index = Math.floor(Math.random() * copy.length);
    var item = copy[index];
    copy.splice(index, 1);
    return item;
  };
}

// Shuffle array
function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

export default Quiz;
