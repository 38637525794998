// Import React stuff
import { useEffect, createContext } from "react";
import { VariantContext } from "context/variantContext";
import { useDispatch, useSelector } from "react-redux";
import {
  timeReducer,
  setStartTime,
  initializeStartTime,
} from "store/gameSlice";

import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import "i18n/config";

// Styles
import "./assets/styles/style.scss";

// Import views and components
import Start from "views/Intro/Start/Start";
import Logo from "views/Intro/Logo/Logo";
import Intro from "views/Intro/Intro";

import Dashboard from "views/Dashboard/Dashboard";
import GameQuiz from "views/GameQuiz/GameQuiz";

import FixedTopBar from "components/FixedTopBar/FixedTopBar";
import FixedBottomBar from "components/FixedBottomBar/FixedBottomBar";

import Bravo from "views/Bravo/Bravo";
import Menjava from "views/Menjava/Menjava";
import Finale from "views/Finale/Finale";
import Cestitke from "views/Cestitke/Cestitke";
import QuizIntro from "views/Menjava/QuizIntro";
import Optin from "views/Optin/Optin";

// Import media
//import soundBG from "./assets/sounds/bg.mp3";

const useRouteTimer = (activeRoutes) => {
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    if (activeRoutes.includes(location.pathname)) {
      dispatch(initializeStartTime()); // Initialize the start time if not already done

      const intervalId = setInterval(() => {
        // Pass the current timestamp to the timeReducer
        dispatch(timeReducer(Date.now()));
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [dispatch, location, activeRoutes]);
};

function App() {
  const location = useLocation();

  let variant;
  if (location.pathname.startsWith("/45")) {
    variant = "45";
  }

  if (location.pathname.startsWith("/30")) {
    variant = "30";
  }

  if (location.pathname.startsWith("/lov-45")) {
    variant = "lov-45";
  }
  if (location.pathname.startsWith("/lov-30")) {
    variant = "lov-30";
  }

  useRouteTimer([
    `/${variant}/game/game1`,
    `/${variant}/game/game2`,
    `/${variant}/game/game3`,
    `/${variant}/game/game4`,
    `/${variant}/game/game5`,
    `/${variant}/game/game6`,
    `/${variant}/game/game7`,
    `/${variant}/dashboard`,
    `/${variant}/bravo`,
    `/${variant}/menjava`,
    `/${variant}/menjava-kviz`,
  ]);

  const RouteWrapper = ({ element }) => (
    <>
      <FixedTopBar />
      {element}
      <FixedBottomBar />
    </>
  );

  return (
    <>
      <VariantContext.Provider value={variant}>
        <AnimatePresence mode="wait">
          <Routes location={location} key={location.pathname}>
            {["/45", "/30", "/lov-45", "/lov-30"].map((prefix) => (
              <>
                <Route
                  path="/"
                  element={<Navigate replace to={`${prefix}`} />}
                />
                <Route
                  path={`${prefix}`}
                  element={<RouteWrapper element={<Start />} />}
                />
                <Route
                  path={`${prefix}/logo`}
                  element={<RouteWrapper element={<Logo />} />}
                />

                <Route
                  path={`${prefix}/intro/:intro`}
                  element={<RouteWrapper element={<Intro />} />}
                />
                <Route
                  path={`${prefix}/dashboard`}
                  element={<RouteWrapper element={<Dashboard />} />}
                />
                <Route
                  path={`${prefix}/game/:game`}
                  element={<RouteWrapper element={<GameQuiz />} />}
                />
                <Route
                  path={`${prefix}/bravo`}
                  element={<RouteWrapper element={<Bravo />} />}
                />
                <Route
                  path={`${prefix}/menjava-kviz`}
                  element={<RouteWrapper element={<QuizIntro />} />}
                />
                <Route
                  path={`${prefix}/menjava`}
                  element={<RouteWrapper element={<Menjava />} />}
                />
                <Route
                  path={`${prefix}/finale`}
                  element={<RouteWrapper element={<Finale />} />}
                />
                <Route
                  path={`${prefix}/cestitke`}
                  element={<RouteWrapper element={<Cestitke />} />}
                />
                <Route
                  path={`${prefix}/optin`}
                  element={<RouteWrapper element={<Optin />} />}
                />
              </>
            ))}
          </Routes>
        </AnimatePresence>
      </VariantContext.Provider>
    </>
  );
}

export default App;
