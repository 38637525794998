import { useNavigate } from "react-router-dom";
import imgPin from "assets/images/lov/pin.svg";
import imgMosnjicek from "assets/images/lov/mosnjicek.svg";

function Button({ onClick, className, label, showPrev, disabled }) {
  const navigate = useNavigate();

  const clickHandler = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <>
      <button
        disabled={disabled}
        className={`btn main-button mt-3 d-flex align-items-center justify-content-center ${className}`}
        onClick={clickHandler}>
        {label === "Zemljevid" && <img className="pe-2" src={imgPin} />}
        {label === "Menjava v evre" && (
          <img className="pe-2" src={imgMosnjicek} />
        )}
        <span>{label}</span>
      </button>
      {showPrev === "true" && (
        <button
          onClick={() => navigate(-1)}
          className="btn btn-sm back-button mb-3 fw-bold">
          NAZAJ
        </button>
      )}
    </>
  );
}

export default Button;
