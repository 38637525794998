import { useContext } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { VariantContext } from "context/variantContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CurrentGameStatus from "components/CurrentGameStatus/CurrentGameStatus";
import Button from "components/Button/Button";

import Wrapper from "components/Wrapper/Wrapper";
import ConfettiAtlant from "utils/ConfettiAtlant";

const Cestitke = () => {
  const variant = useContext(VariantContext);
  const navigate = useNavigate();

  const isNagradna = variant === "lov-45" || variant === "lov-30";

  const longGame = variant === "lov-45" || variant === "45";

  return (
    <div className="main-container finale theme-gold">
      <ConfettiAtlant />
      <Wrapper className="bravo  w-100 text-center theme-dark position-relative px-3">
        <div
          className=" rounded px-0"
          style={isNagradna ? { marginTop: "18px" } : { marginTop: "78px" }}>
          <h1 className="text-gold mb-3" style={{ fontSize: "40px" }}>
            Uspelo vam je!
          </h1>
        </div>
        <div className="m-auto mb-3">
          <p className="text-white">
            Z malo sreče, veliko iznajdljivosti in še več znanja, ste od
            izgubljenih tolarjev prišli do čisto svežih evrov!
          </p>
          <p className="text-white">
            <h3 className="text-gold">Ste vedeli?</h3>
            Poleg tolarjev, lahko na blagajni Banke Slovenije kupite tudi evrske
            spominske ali zbirateljske kovance, kot je npr. kovanec za 3 €, ki
            je zakonito plačilno sredstvo le na območju Republike Slovenije.
          </p>

          {!isNagradna && (
            <p className="text-white">
              Hvala za igranje. Odpravite se nazaj na zbirno mesto.
            </p>
          )}
        </div>

        {isNagradna && (
          <Button
            label={`PRIJAVA V NAGRADNO IGRO`}
            className="mt-0 mb-4 w-75 mx-auto px-0"
            onClick={() => navigate(`/${variant}/optin`)}
          />
        )}

        <svg
          className="cestitke position-absolute start-0 w-100"
          style={isNagradna ? { top: "-80px" } : { top: "0" }}
          viewBox="0 0 375 338"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g id="cestitke">
            <g id="confeti">
              <g id="Frame" clip-path="url(#clip0_139_349)">
                <path
                  id="Vector"
                  d="M77.3223 201.111C77.6791 201.119 77.942 201.287 78.1003 201.612C78.6636 202.766 79.227 203.919 79.7904 205.073C80.0077 205.518 80.2304 205.962 80.4423 206.409C80.6381 206.826 80.9654 207.04 81.4108 207.105C82.6234 207.278 83.8333 207.457 85.0459 207.636C85.5636 207.711 86.0787 207.79 86.5965 207.863C86.9452 207.912 87.2001 208.088 87.3235 208.424C87.4496 208.768 87.3879 209.087 87.1277 209.347C86.3014 210.168 85.4697 210.981 84.6408 211.796C84.1767 212.254 83.7072 212.703 83.2484 213.166C82.9828 213.435 82.8809 213.77 82.9453 214.147C83.2458 215.921 83.5462 217.695 83.8494 219.469C83.9111 219.832 83.8064 220.133 83.5113 220.352C83.2216 220.566 82.905 220.58 82.5885 220.412C81.0808 219.613 79.5731 218.814 78.0654 218.012C77.9983 217.977 77.9312 217.942 77.8642 217.906C77.502 217.711 77.1372 217.711 76.775 217.906C75.5999 218.532 74.4249 219.155 73.2499 219.778C72.8555 219.989 72.4585 220.198 72.0614 220.406C71.7395 220.577 71.4229 220.572 71.1251 220.352C70.8247 220.13 70.7254 219.827 70.7898 219.458C71.0929 217.69 71.3907 215.918 71.6912 214.15C71.7583 213.76 71.6456 213.421 71.3693 213.148C70.6181 212.4 69.8589 211.661 69.1024 210.918C68.5765 210.401 68.0507 209.886 67.5276 209.366C67.2647 209.104 67.1842 208.789 67.3076 208.435C67.431 208.085 67.6912 207.909 68.0507 207.858C69.3062 207.676 70.5618 207.489 71.8173 207.302C72.3216 207.229 72.826 207.159 73.3277 207.08C73.7059 207.021 73.9876 206.815 74.1593 206.466C74.7817 205.195 75.4041 203.925 76.0238 202.655C76.1955 202.302 76.3645 201.948 76.5416 201.598C76.7052 201.281 76.9655 201.119 77.3223 201.111Z"
                  fill="#DCCAA7"
                />
              </g>
              <g id="Frame_2" clip-path="url(#clip1_139_349)">
                <path
                  id="Vector_2"
                  d="M105.068 19C105.425 19.0081 105.688 19.1761 105.846 19.5011C106.41 20.6549 106.973 21.8088 107.536 22.9626C107.754 23.4068 107.976 23.851 108.188 24.2979C108.384 24.715 108.712 24.929 109.157 24.994C110.369 25.1674 111.579 25.3461 112.792 25.5249C113.31 25.6007 113.825 25.6793 114.343 25.7524C114.691 25.8012 114.946 25.9772 115.07 26.3131C115.196 26.6571 115.134 26.9767 114.874 27.2367C114.047 28.0574 113.216 28.87 112.387 29.6852C111.923 30.143 111.453 30.5926 110.995 31.0558C110.729 31.3239 110.627 31.6598 110.691 32.0362C110.992 33.8103 111.292 35.5844 111.595 37.3585C111.657 37.7215 111.553 38.0221 111.257 38.2415C110.968 38.4555 110.651 38.469 110.335 38.3011C108.827 37.5021 107.319 36.7031 105.811 35.9013C105.744 35.8661 105.677 35.8309 105.61 35.7957C105.248 35.6007 104.883 35.6007 104.521 35.7957C103.346 36.4214 102.171 37.0444 100.996 37.6673C100.602 37.8786 100.205 38.0871 99.8075 38.2957C99.4856 38.4663 99.169 38.4609 98.8712 38.2415C98.5708 38.0194 98.4715 37.7161 98.5359 37.3477C98.839 35.579 99.1368 33.8076 99.4373 32.039C99.5044 31.6489 99.3917 31.3104 99.1154 31.0368C98.3642 30.2892 97.605 29.5498 96.8485 28.8077C96.3226 28.2903 95.7968 27.7757 95.2737 27.2557C95.0108 26.9929 94.9303 26.6787 95.0537 26.3239C95.1771 25.9745 95.4373 25.7985 95.7968 25.747C97.0523 25.5655 98.3079 25.3786 99.5634 25.1917C100.068 25.1186 100.572 25.0482 101.074 24.9696C101.452 24.9101 101.734 24.7042 101.905 24.3548C102.528 23.0845 103.15 21.8142 103.77 20.5439C103.942 20.1918 104.111 19.8369 104.288 19.4875C104.451 19.1706 104.712 19.0081 105.068 19Z"
                  fill="#DCCAA7"
                />
              </g>
              <g id="Frame_3" clip-path="url(#clip2_139_349)">
                <path
                  id="Vector_3"
                  d="M50.2192 243.251C50.4336 243.138 50.6439 243.15 50.8429 243.287C51.5502 243.775 52.2575 244.262 52.9649 244.75C53.2374 244.937 53.5131 245.123 53.7834 245.314C54.0344 245.492 54.2978 245.509 54.5831 245.401C55.3588 245.103 56.1347 244.809 56.9121 244.514C57.2439 244.388 57.575 244.265 57.9059 244.137C58.1286 244.051 58.3368 244.069 58.5186 244.225C58.7046 244.384 58.7713 244.59 58.7009 244.827C58.4758 245.578 58.2449 246.325 58.0165 247.073C57.889 247.493 57.7557 247.91 57.6332 248.331C57.5622 248.574 57.6103 248.804 57.7702 249.002C58.522 249.938 59.2737 250.873 60.0271 251.808C60.181 251.999 60.2161 252.209 60.1119 252.434C60.0091 252.654 59.8256 252.766 59.5835 252.772C58.4304 252.802 57.2774 252.833 56.1234 252.861C56.0723 252.863 56.0211 252.864 55.9699 252.866C55.6919 252.871 55.4754 252.991 55.3235 253.224C54.8284 253.975 54.3324 254.725 53.8364 255.475C53.6707 255.728 53.5024 255.98 53.3342 256.233C53.1983 256.438 53.0087 256.539 52.7611 256.509C52.5109 256.478 52.354 256.334 52.2731 256.098C51.8813 254.967 51.4855 253.836 51.0921 252.706C51.0058 252.457 50.8295 252.296 50.5771 252.228C49.8896 252.039 49.2 251.858 48.5112 251.674C48.0319 251.545 47.5535 251.418 47.0749 251.287C46.834 251.221 46.6846 251.064 46.6432 250.816C46.6035 250.572 46.701 250.384 46.8977 250.235C47.5842 249.717 48.2689 249.195 48.9536 248.672C49.2293 248.464 49.5058 248.257 49.7782 248.046C49.9834 247.887 50.084 247.674 50.073 247.414C50.0317 246.468 49.9905 245.523 49.9477 244.578C49.9357 244.317 49.9214 244.054 49.9135 243.792C49.9082 243.554 50.0101 243.373 50.2192 243.251Z"
                  fill="#DCCAA7"
                />
              </g>
              <g id="Frame_4" clip-path="url(#clip3_139_349)">
                <path
                  id="Vector_4"
                  d="M309.975 51.3118C310.189 51.1991 310.4 51.2105 310.599 51.348C311.306 51.8354 312.013 52.3228 312.721 52.8102C312.993 52.9977 313.269 53.1834 313.539 53.3743C313.79 53.553 314.054 53.5701 314.339 53.4615C315.115 53.1636 315.891 52.8697 316.668 52.575C317 52.4489 317.331 52.3252 317.662 52.1975C317.884 52.1112 318.093 52.13 318.274 52.2852C318.46 52.4443 318.527 52.6509 318.457 52.8882C318.232 53.6385 318.001 54.3859 317.772 55.134C317.645 55.5536 317.512 55.9702 317.389 56.3912C317.318 56.6349 317.366 56.8643 317.526 57.0626C318.278 57.9982 319.03 58.9337 319.783 59.8684C319.937 60.0597 319.972 60.2695 319.868 60.4945C319.765 60.7146 319.582 60.8266 319.339 60.8328C318.186 60.863 317.033 60.8932 315.879 60.9218C315.828 60.9233 315.777 60.9249 315.726 60.9264C315.448 60.9318 315.231 61.0519 315.079 61.2848C314.584 62.0362 314.088 62.786 313.592 63.5358C313.427 63.7888 313.258 64.041 313.09 64.2932C312.954 64.4986 312.765 64.5996 312.517 64.5697C312.267 64.539 312.11 64.3948 312.029 64.1589C311.637 63.0279 311.241 61.8971 310.848 60.767C310.762 60.5175 310.585 60.3572 310.333 60.2886C309.645 60.0998 308.956 59.9185 308.267 59.7347C307.788 59.6061 307.309 59.479 306.831 59.3479C306.59 59.2812 306.441 59.1245 306.399 58.877C306.359 58.6327 306.457 58.4444 306.654 58.2961C307.34 57.7773 308.025 57.2552 308.709 56.7331C308.985 56.5246 309.262 56.3176 309.534 56.1067C309.739 55.9475 309.84 55.7348 309.829 55.4746C309.788 54.5292 309.746 53.5837 309.704 52.6392C309.692 52.3774 309.677 52.1149 309.669 51.8529C309.664 51.6143 309.766 51.4339 309.975 51.3118Z"
                  fill="#DCCAA7"
                />
              </g>
              <g id="Frame_5" clip-path="url(#clip4_139_349)">
                <path
                  id="Vector_5"
                  d="M304.956 270.909C305.227 271.142 305.323 271.438 305.239 271.79C304.943 273.039 304.646 274.289 304.349 275.538C304.235 276.019 304.126 276.504 304.006 276.984C303.893 277.43 304.01 277.803 304.313 278.136C305.14 279.04 305.962 279.946 306.786 280.853C307.138 281.24 307.486 281.628 307.84 282.013C308.078 282.272 308.164 282.57 308.046 282.907C307.925 283.253 307.675 283.461 307.308 283.497C306.149 283.607 304.991 283.707 303.833 283.812C303.184 283.871 302.535 283.921 301.887 283.987C301.512 284.026 301.22 284.221 301.03 284.553C300.137 286.115 299.244 287.677 298.352 289.24C298.17 289.56 297.898 289.726 297.531 289.708C297.171 289.69 296.918 289.499 296.78 289.169C296.121 287.594 295.463 286.02 294.806 284.444C294.777 284.374 294.747 284.304 294.718 284.235C294.562 283.854 294.28 283.623 293.876 283.544C292.571 283.282 291.267 283.017 289.964 282.753C289.525 282.666 289.086 282.576 288.647 282.485C288.289 282.413 288.048 282.208 287.957 281.849C287.866 281.487 287.982 281.189 288.265 280.945C289.622 279.771 290.976 278.591 292.33 277.414C292.63 277.155 292.757 276.822 292.717 276.435C292.611 275.381 292.494 274.328 292.38 273.274C292.302 272.54 292.222 271.809 292.147 271.075C292.111 270.705 292.248 270.411 292.569 270.215C292.886 270.024 293.198 270.053 293.509 270.241C294.594 270.897 295.683 271.55 296.772 272.202C297.209 272.465 297.643 272.731 298.081 272.989C298.411 273.183 298.759 273.202 299.114 273.041C300.401 272.454 301.688 271.867 302.973 271.279C303.329 271.115 303.685 270.948 304.043 270.791C304.371 270.65 304.675 270.689 304.956 270.909Z"
                  fill="#DCCAA7"
                />
              </g>
              <g id="Frame_6" clip-path="url(#clip5_139_349)">
                <path
                  id="Vector_6"
                  d="M338.204 164.824C338.704 165.255 338.883 165.804 338.728 166.455C338.179 168.767 337.63 171.079 337.081 173.391C336.87 174.282 336.667 175.179 336.445 176.067C336.235 176.894 336.452 177.584 337.013 178.2C338.544 179.872 340.065 181.549 341.59 183.229C342.241 183.945 342.886 184.662 343.541 185.375C343.982 185.854 344.14 186.405 343.922 187.031C343.699 187.671 343.235 188.056 342.557 188.122C340.412 188.326 338.268 188.512 336.124 188.704C334.923 188.814 333.723 188.906 332.523 189.03C331.828 189.101 331.288 189.462 330.938 190.076C329.284 192.967 327.631 195.858 325.981 198.752C325.643 199.343 325.14 199.651 324.46 199.618C323.795 199.584 323.326 199.231 323.07 198.619C321.852 195.706 320.633 192.792 319.418 189.875C319.364 189.745 319.309 189.616 319.254 189.487C318.965 188.783 318.443 188.354 317.696 188.208C315.281 187.723 312.868 187.234 310.455 186.746C309.643 186.585 308.83 186.417 308.017 186.249C307.356 186.115 306.91 185.735 306.742 185.072C306.573 184.401 306.787 183.851 307.312 183.399C309.822 181.225 312.329 179.041 314.836 176.864C315.39 176.384 315.626 175.768 315.552 175.052C315.355 173.1 315.138 171.151 314.927 169.201C314.782 167.843 314.634 166.49 314.497 165.131C314.429 164.447 314.683 163.903 315.276 163.54C315.863 163.185 316.442 163.239 317.017 163.588C319.026 164.802 321.042 166.009 323.057 167.217C323.865 167.704 324.669 168.196 325.479 168.673C326.09 169.032 326.734 169.068 327.39 168.77C329.773 167.683 332.155 166.597 334.533 165.508C335.192 165.206 335.851 164.897 336.514 164.605C337.121 164.344 337.684 164.417 338.204 164.824Z"
                  fill="#DCCAA7"
                />
              </g>
              <g>
                <path
                  id="Vector_7"
                  d="M118.099 177.4C119.969 177.4 121.485 175.884 121.485 174.015C121.485 172.145 119.969 170.629 118.099 170.629C116.229 170.629 114.713 172.145 114.713 174.015C114.713 175.884 116.229 177.4 118.099 177.4Z"
                  fill="#85C1C8"
                />
              </g>

              <g>
                <path
                  id="Vector_8"
                  d="M82.1454 239.24C84.0152 239.24 85.531 237.724 85.531 235.854C85.531 233.985 84.0152 232.469 82.1454 232.469C80.2756 232.469 78.7598 233.985 78.7598 235.854C78.7598 237.724 80.2756 239.24 82.1454 239.24Z"
                  fill="#85C1C8"
                />
              </g>
              <g>
                <path
                  id="Vector_9"
                  d="M330.386 227.771C332.255 227.771 333.771 226.255 333.771 224.386C333.771 222.516 332.255 221 330.386 221C328.516 221 327 222.516 327 224.386C327 226.255 328.516 227.771 330.386 227.771Z"
                  fill="#026B4E"
                />
              </g>
              <g>
                <path
                  id="Vector_10"
                  d="M238.934 189.33C240.804 189.33 242.32 187.814 242.32 185.944C242.32 184.074 240.804 182.558 238.934 182.558C237.065 182.558 235.549 184.074 235.549 185.944C235.549 187.814 237.065 189.33 238.934 189.33Z"
                  fill="#E6695C"
                />
              </g>
              <g>
                <path
                  id="Vector_11"
                  d="M130.386 14.7713C132.255 14.7713 133.771 13.2555 133.771 11.3856C133.771 9.5158 132.255 8 130.386 8C128.516 8 127 9.5158 127 11.3856C127 13.2555 128.516 14.7713 130.386 14.7713Z"
                  fill="#E6695C"
                />
              </g>
              <g>
                <path
                  id="Vector_12"
                  d="M41.6885 168.43C45.2336 168.653 48.6753 169.713 51.7315 171.524C54.7877 173.334 57.3713 175.843 59.2707 178.845C59.803 179.695 60.4979 180.631 60.1294 181.678C60.001 182.08 59.7402 182.426 59.3896 182.66C59.039 182.894 58.6193 183.002 58.1991 182.967C57.6753 182.854 57.2031 182.572 56.8554 182.165C56.5077 181.757 56.3038 181.246 56.2751 180.711C56.2465 180.176 56.3946 179.646 56.6968 179.204C56.999 178.761 57.4383 178.431 57.9471 178.262C60.4058 177.214 62.8865 178.835 64.8893 180.128C69.4528 183.076 74.4723 185.741 80.0021 186.077C85.1183 186.388 90.6048 184.382 93.4506 179.945C93.9484 179.169 92.7123 178.452 92.2169 179.224C89.3946 183.624 83.6891 185.205 78.7282 184.524C75.7719 184.058 72.9173 183.089 70.2881 181.659C68.9245 180.951 67.6021 180.167 66.3063 179.342C65.0734 178.482 63.7665 177.733 62.4011 177.104C60.1239 176.168 57.1093 176.241 55.5186 178.395C54.0347 180.404 55.1815 183.749 57.6635 184.314C58.208 184.421 58.7706 184.392 59.3009 184.228C59.8312 184.065 60.3126 183.772 60.702 183.377C61.0913 182.981 61.3765 182.495 61.5319 181.963C61.6873 181.43 61.708 180.867 61.5923 180.324C61.3446 179.141 60.4537 177.983 59.7641 177.016C57.692 174.101 54.9923 171.688 51.8641 169.955C48.7358 168.222 45.2585 167.212 41.6884 167.001C40.7693 166.956 40.7717 168.384 41.6884 168.43L41.6885 168.43Z"
                  fill="#E6695C"
                />
              </g>
              <g>
                <path
                  id="Vector_13"
                  d="M287.688 12.4298C291.234 12.6531 294.675 13.7134 297.731 15.5237C300.788 17.3341 303.371 19.8429 305.271 22.8445C305.803 23.6955 306.498 24.6306 306.129 25.678C306.001 26.0796 305.74 26.4258 305.39 26.6599C305.039 26.8941 304.619 27.0024 304.199 26.9672C303.675 26.8544 303.203 26.5724 302.855 26.1647C302.508 25.757 302.304 25.2462 302.275 24.7112C302.246 24.1761 302.395 23.6465 302.697 23.2039C302.999 22.7614 303.438 22.4306 303.947 22.2625C306.406 21.2137 308.886 22.8346 310.889 24.1281C315.453 27.0757 320.472 29.7413 326.002 30.0772C331.118 30.388 336.605 28.3819 339.451 23.9451C339.948 23.1689 338.712 22.4516 338.217 23.224C335.395 27.6242 329.689 29.2046 324.728 28.5245C321.772 28.0579 318.917 27.0888 316.288 25.6589C314.925 24.9513 313.602 24.1669 312.306 23.3423C311.073 22.482 309.767 21.7328 308.401 21.1035C306.124 20.1681 303.109 20.2412 301.519 22.3949C300.035 24.4041 301.182 27.7492 303.664 28.3141C304.208 28.4214 304.771 28.392 305.301 28.2284C305.831 28.0648 306.313 27.7722 306.702 27.3767C307.091 26.9813 307.376 26.4954 307.532 25.9627C307.687 25.4299 307.708 24.8669 307.592 24.3242C307.345 23.141 306.454 21.9831 305.764 21.016C303.692 18.101 300.992 15.6881 297.864 13.9548C294.736 12.2216 291.258 11.2122 287.688 11.0011C286.769 10.9556 286.772 12.3844 287.688 12.4298L287.688 12.4298Z"
                  fill="#E6695C"
                />
              </g>
              <g>
                <path
                  id="Vector_14"
                  d="M326.967 265.997C324.591 264.468 322.612 262.397 321.191 259.955C319.771 257.513 318.949 254.769 318.793 251.949C318.753 251.151 318.633 250.233 319.295 249.648C319.54 249.419 319.855 249.279 320.19 249.253C320.524 249.226 320.857 249.313 321.135 249.501C321.455 249.782 321.674 250.161 321.758 250.579C321.841 250.997 321.785 251.431 321.597 251.813C321.41 252.196 321.101 252.507 320.72 252.697C320.339 252.887 319.905 252.946 319.487 252.866C317.373 252.641 316.278 250.554 315.389 248.879C313.362 245.063 310.909 241.266 307.199 238.89C303.766 236.69 299.178 235.957 295.482 237.935C294.835 238.281 295.416 239.259 296.059 238.914C299.725 236.952 304.3 238.066 307.482 240.462C309.354 241.932 310.961 243.711 312.233 245.723C312.905 246.743 313.52 247.801 314.1 248.876C314.623 249.951 315.24 250.978 315.944 251.944C317.163 253.477 319.285 254.594 321.225 253.715C321.657 253.492 322.031 253.17 322.316 252.776C322.602 252.382 322.791 251.927 322.87 251.446C322.948 250.966 322.913 250.474 322.767 250.01C322.621 249.546 322.368 249.122 322.029 248.773C321.693 248.487 321.291 248.29 320.859 248.198C320.427 248.106 319.98 248.123 319.556 248.246C319.132 248.37 318.746 248.597 318.431 248.907C318.117 249.216 317.884 249.599 317.754 250.021C317.555 251.013 317.55 252.034 317.742 253.027C318.051 255.855 318.99 258.577 320.491 260.993C321.992 263.41 324.016 265.459 326.413 266.989C327.034 267.377 327.586 266.384 326.967 265.997L326.967 265.997Z"
                  fill="#E6695C"
                />
              </g>
              <g>
                <path
                  id="Vector_15"
                  d="M194.373 176.061C191.553 175.883 188.816 175.04 186.385 173.6C183.955 172.16 181.9 170.165 180.389 167.777C179.966 167.101 179.413 166.357 179.706 165.524C179.808 165.204 180.016 164.929 180.294 164.743C180.573 164.557 180.907 164.47 181.241 164.498C181.658 164.588 182.033 164.812 182.31 165.137C182.587 165.461 182.749 165.867 182.771 166.293C182.794 166.718 182.676 167.14 182.436 167.492C182.196 167.843 181.846 168.107 181.442 168.24C179.486 169.074 177.513 167.785 175.92 166.756C172.291 164.412 168.298 162.292 163.9 162.025C159.831 161.778 155.467 163.373 153.204 166.902C152.808 167.519 153.791 168.09 154.185 167.476C156.43 163.976 160.968 162.719 164.913 163.26C167.265 163.631 169.535 164.402 171.626 165.539C172.711 166.102 173.763 166.726 174.793 167.381C175.774 168.066 176.813 168.662 177.899 169.162C179.71 169.906 182.108 169.848 183.373 168.135C183.641 167.729 183.811 167.266 183.868 166.783C183.926 166.3 183.869 165.81 183.704 165.352C183.538 164.895 183.267 164.482 182.914 164.148C182.56 163.814 182.133 163.567 181.667 163.427C181.234 163.342 180.787 163.365 180.365 163.495C179.943 163.625 179.56 163.858 179.251 164.173C178.941 164.487 178.714 164.874 178.59 165.297C178.467 165.721 178.45 166.169 178.542 166.6C178.852 167.564 179.345 168.457 179.997 169.232C181.645 171.55 183.792 173.469 186.28 174.848C188.768 176.226 191.534 177.029 194.373 177.197C195.104 177.233 195.102 176.097 194.373 176.061L194.373 176.061Z"
                  fill="#E6695C"
                />
              </g>
              <g>
                <path
                  id="Vector_16"
                  d="M267.665 162H264.28V173.124H267.665V162Z"
                  fill="#E6695C"
                />
              </g>
              <g>
                <path
                  id="Vector_17"
                  d="M142.21 204.298L144.562 201.946L136.831 194.215L134.479 196.567L142.21 204.298Z"
                  fill="#B99450"
                />
              </g>
              <g>
                <path
                  id="Vector_18"
                  d="M84.7311 10.084L87.084 7.73108L79.3529 3.69557e-05L77 2.35296L84.7311 10.084Z"
                  fill="#B99450"
                />
              </g>
              <g>
                <path
                  id="Vector_19"
                  d="M288.009 194.898L290.362 197.251L298.093 189.52L295.74 187.167L288.009 194.898Z"
                  fill="#B99450"
                />
              </g>
              <g>
                <path
                  id="Vector_20"
                  d="M32.0201 276.872L34.373 279.225L42.1041 271.494L39.7512 269.141L32.0201 276.872Z"
                  fill="#85C1C8"
                />
              </g>
            </g>
            <g>
              <path
                id="Vector_21"
                d="M329.422 280.34C332.039 280.34 334.161 278.218 334.161 275.6C334.161 272.982 332.039 270.86 329.422 270.86C326.804 270.86 324.682 272.982 324.682 275.6C324.682 278.218 326.804 280.34 329.422 280.34Z"
                fill="#026B4E"
              />
            </g>
            <g>
              <path
                id="Vector_22"
                d="M246.74 30.4798C249.358 30.4798 251.48 28.3576 251.48 25.7399C251.48 23.1221 249.358 21 246.74 21C244.122 21 242 23.1221 242 25.7399C242 28.3576 244.122 30.4798 246.74 30.4798Z"
                fill="#026B4E"
              />
            </g>
            <g id="bankovci">
              {longGame ? (
                <g id="70">
                  <g
                    id="Frame_9"
                    clip-path="url(#clip8_139_349)"
                    filter="url(#filter1_d_139_349)">
                    <g id="Group_11">
                      <path
                        id="Vector_53"
                        d="M199.462 220L108 273.579L137.317 323.624L228.779 270.045L199.462 220Z"
                        fill="#F7F2CF"
                      />
                      <path
                        id="Vector_54"
                        d="M185.996 227.888L128.405 261.625L157.722 311.67L215.313 277.933L185.996 227.888Z"
                        fill="#EAC7A9"
                      />
                      <g id="Group_12">
                        <g id="Group_13">
                          <path
                            id="Vector_55"
                            d="M151.505 251.42L153.197 252.646L155.079 251.821L154.561 253.787L155.899 255.36L153.862 255.444L152.688 257.241L151.971 255.304L150.003 254.794L151.643 253.556L151.505 251.42Z"
                            fill="#F7F2CF"
                          />
                          <path
                            id="Vector_56"
                            d="M171.754 285.987L173.386 287.11L175.328 286.388L174.81 288.355L176.149 289.927L174.112 290.012L172.938 291.808L172.221 289.872L170.253 289.362L171.832 288.021L171.754 285.987Z"
                            fill="#F7F2CF"
                          />
                          <path
                            id="Vector_57"
                            d="M178.659 278.062L180.455 279.227L182.336 278.402L181.819 280.369L183.157 281.941L181.016 282.086L179.946 283.822L179.229 281.886L177.261 281.375L178.901 280.138L178.659 278.062Z"
                            fill="#F7F2CF"
                          />
                          <path
                            id="Vector_58"
                            d="M161.154 288.316L162.95 289.482L164.832 288.657L164.21 290.684L165.549 292.256L163.511 292.341L162.441 294.076L161.724 292.14L159.652 291.691L161.292 290.453L161.154 288.316Z"
                            fill="#F7F2CF"
                          />
                          <path
                            id="Vector_59"
                            d="M151.209 285.827L153.004 286.992L154.886 286.167L154.369 288.133L155.707 289.705L153.566 289.851L152.496 291.586L151.779 289.65L149.811 289.14L151.451 287.902L151.209 285.827Z"
                            fill="#F7F2CF"
                          />
                          <path
                            id="Vector_60"
                            d="M181.558 268.048L183.354 269.213L185.235 268.388L184.614 270.415L185.953 271.987L183.915 272.072L182.845 273.808L182.128 271.872L180.056 271.422L181.696 270.184L181.558 268.048Z"
                            fill="#F7F2CF"
                          />
                          <path
                            id="Vector_61"
                            d="M178.707 258.907L180.443 259.969L182.385 259.248L181.764 261.275L183.102 262.847L181.065 262.931L179.994 264.667L179.278 262.731L177.206 262.281L178.785 260.94L178.707 258.907Z"
                            fill="#F7F2CF"
                          />
                          <path
                            id="Vector_62"
                            d="M144.007 279.234L145.639 280.357L147.581 279.635L147.064 281.602L148.402 283.174L146.365 283.259L145.191 285.055L144.474 283.119L142.506 282.609L144.086 281.268L144.007 279.234Z"
                            fill="#F7F2CF"
                          />
                          <path
                            id="Vector_63"
                            d="M141.537 269.317L143.333 270.482L145.215 269.657L144.697 271.624L146.036 273.196L143.895 273.341L142.825 275.077L142.108 273.141L140.139 272.63L141.779 271.393L141.537 269.317Z"
                            fill="#F7F2CF"
                          />
                          <path
                            id="Vector_64"
                            d="M171.161 251.963L172.957 253.128L174.839 252.303L174.321 254.27L175.66 255.842L173.519 255.987L172.449 257.723L171.732 255.787L169.763 255.276L171.403 254.039L171.161 251.963Z"
                            fill="#F7F2CF"
                          />
                          <path
                            id="Vector_65"
                            d="M161.673 249.066L163.469 250.232L165.351 249.407L164.833 251.373L166.172 252.945L164.031 253.091L162.961 254.826L162.244 252.89L160.276 252.38L161.916 251.142L161.673 249.066Z"
                            fill="#F7F2CF"
                          />
                          <path
                            id="Vector_66"
                            d="M144.168 259.321L145.964 260.487L147.846 259.661L147.225 261.689L148.563 263.261L146.526 263.345L145.455 265.081L144.739 263.145L142.667 262.695L144.307 261.457L144.168 259.321Z"
                            fill="#F7F2CF"
                          />
                        </g>
                      </g>
                      <path
                        id="Vector_67"
                        d="M186.1 227.827L161.966 241.965L191.283 292.011L215.417 277.873L186.1 227.827Z"
                        fill="#EAB98F"
                      />
                      <path
                        id="Vector_68"
                        d="M194.19 284.625L176.842 255.01L174.572 253.984L180.811 243.261L192.552 243.59L193.001 245.545L210.142 275.28L194.19 284.625Z"
                        fill="#E19C64"
                      />
                      <path
                        id="Vector_69"
                        d="M185.904 289.479L170.49 263.167L168.547 262.226L174.053 252.625L184.499 252.881L184.887 254.732L200.198 281.106L185.904 289.479Z"
                        fill="#EAAB75"
                      />
                      <path
                        id="Vector_70"
                        d="M191.344 251.504L185.647 254.842L195.923 272.383L201.62 269.046L191.344 251.504Z"
                        fill="#EAC7A9"
                      />
                      <path
                        id="Vector_71"
                        d="M184.529 279.058L176.067 264.612C176.067 264.612 175.082 261.031 177.982 259.332C180.883 257.633 183.671 260.019 183.671 260.019L192.194 274.568L184.529 279.058Z"
                        fill="#EAC7A9"
                      />
                      <g id="Group_14">
                        <path
                          id="Vector_72"
                          d="M196.59 237.898C197.238 238.766 197.696 240.022 198.007 241.503C198.257 242.881 198.18 244.174 197.507 245.4C197.274 245.952 196.98 246.401 196.747 246.953C196.411 247.566 195.789 247.93 195.151 248.027C194.408 248.185 193.666 248.343 192.863 248.397C192.328 248.434 191.836 248.306 191.344 248.178C189.151 247.523 187.398 246.193 186.146 244.293C185.421 243.055 184.756 241.92 184.134 240.621C183.236 238.375 183.529 236.263 184.91 234.346C185.203 233.897 185.557 233.551 185.911 233.205C186.826 232.392 187.819 231.949 188.949 231.979C190.451 231.931 191.824 232.374 193.024 233.473C194.388 234.614 195.442 235.937 196.59 237.898ZM193.302 244.398C193.717 244.155 193.743 243.724 193.932 243.336C194.398 242.231 194.226 241.223 193.621 240.192C193.319 239.676 193.181 239.202 192.878 238.686C192.118 236.914 190.772 236.04 189.071 235.512C188.061 235.687 187.828 236.24 187.819 236.938C187.759 238.497 188.277 239.857 189.21 240.974C190.082 241.988 191.161 242.88 192.137 243.833C192.482 244.185 192.871 244.373 193.302 244.398Z"
                          fill="#C77029"
                        />
                        <path
                          id="Vector_73"
                          d="M176.315 249.36C175.615 249.354 175.045 248.856 174.743 248.34C174.26 247.514 173.733 246.853 173.206 246.191C172.274 245.074 171.859 243.654 171.608 242.276C171.513 241.639 171.686 240.984 172.143 240.577C173.205 239.539 174.37 238.441 175.898 237.962C176.52 237.598 177.245 237.173 177.987 237.015C178.773 236.694 179.265 236.821 179.688 237.543C179.93 237.956 180.232 238.472 180.474 238.885C180.672 239.461 180.439 240.014 179.921 240.317C178.972 240.596 177.919 240.936 176.926 241.379C176.348 241.579 175.83 241.882 175.312 242.186C175.666 243.503 175.986 244.286 176.901 245.136C178.023 244.201 179.387 243.679 180.932 243.467C183.919 243.103 185.775 244.372 186.855 246.928C187.295 247.917 187.364 248.985 187.105 249.968C186.778 251.546 186.225 252.979 184.896 254.034C184.128 254.623 183.36 255.212 182.307 255.551C180.615 255.988 179.138 255.606 177.835 254.567C177.472 253.948 177.662 253.56 178.119 253.153C178.551 253.178 178.922 253.099 179.414 253.226C180.502 253.421 181.495 252.978 182.427 252.432C182.945 252.128 183.299 251.782 183.532 251.23C183.765 250.678 183.937 250.022 184.127 249.634C184.118 248.669 183.756 248.05 183.125 247.449C182.271 246.702 181.304 246.714 180.311 247.157C180.207 247.218 180.104 247.279 179.897 247.4C178.8 247.904 177.722 248.674 176.315 249.36Z"
                          fill="#C77029"
                        />
                      </g>
                    </g>
                  </g>
                  <g id="Frame_10" clip-path="url(#clip9_139_349)">
                    <g id="Group_15">
                      <path
                        id="Vector_74"
                        d="M250.845 246.812L143.389 274.636L158.178 331.752L265.635 303.928L250.845 246.812Z"
                        fill="#CAE7EF"
                      />
                      <path
                        id="Vector_75"
                        d="M236.478 250.532L167.537 268.383L182.326 325.5L251.267 307.649L236.478 250.532Z"
                        fill="#B2D8E4"
                      />
                      <path
                        id="Vector_76"
                        d="M246.982 247.812L239.254 249.813L254.044 306.93L261.771 304.929L246.982 247.812Z"
                        fill="#84B4C3"
                      />
                      <path
                        id="Vector_77"
                        d="M229.234 252.408L207.139 258.129L221.928 315.246L244.023 309.524L229.234 252.408Z"
                        fill="#9DC7D4"
                      />
                      <g id="Group_16">
                        <g id="Group_17">
                          <path
                            id="Vector_78"
                            d="M199.157 267.11L200.62 268.78L202.887 268.577L201.69 270.423L202.58 272.369L200.4 271.909L198.837 273.338L198.671 271.205L196.729 270.171L198.809 269.249L199.157 267.11Z"
                            fill="#CAE7EF"
                          />
                          <path
                            id="Vector_79"
                            d="M209.814 308.267L211.277 309.937L213.513 309.614L212.316 311.46L213.237 313.527L211.057 313.067L209.495 314.496L209.298 312.242L207.355 311.209L209.466 310.406L209.814 308.267Z"
                            fill="#CAE7EF"
                          />
                          <path
                            id="Vector_80"
                            d="M219.328 302.219L220.791 303.889L222.905 303.597L221.829 305.412L222.751 307.478L220.57 307.018L218.887 308.479L218.69 306.225L216.868 305.16L218.859 304.389L219.328 302.219Z"
                            fill="#CAE7EF"
                          />
                          <path
                            id="Vector_81"
                            d="M198.44 307.627L199.903 309.297L202.018 309.006L200.821 310.852L201.743 312.918L199.683 312.427L198 313.887L197.803 311.633L195.981 310.569L197.972 309.797L198.44 307.627Z"
                            fill="#CAE7EF"
                          />
                          <path
                            id="Vector_82"
                            d="M189.028 302.126L190.37 303.827L192.637 303.624L191.44 305.471L192.33 307.417L190.271 306.926L188.587 308.386L188.421 306.252L186.479 305.219L188.559 304.296L189.028 302.126Z"
                            fill="#CAE7EF"
                          />
                          <path
                            id="Vector_83"
                            d="M225.128 292.778L226.591 294.448L228.858 294.245L227.661 296.092L228.551 298.038L226.371 297.578L224.809 299.007L224.643 296.873L222.7 295.84L224.66 294.948L225.128 292.778Z"
                            fill="#CAE7EF"
                          />
                          <path
                            id="Vector_84"
                            d="M224.937 282.585L226.279 284.286L228.515 283.963L227.349 285.93L228.239 287.876L226.18 287.385L224.497 288.845L224.331 286.711L222.357 285.558L224.468 284.755L224.937 282.585Z"
                            fill="#CAE7EF"
                          />
                          <path
                            id="Vector_85"
                            d="M183.403 293.339L184.866 295.009L187.102 294.686L185.936 296.653L186.826 298.599L184.646 298.139L183.083 299.568L182.918 297.434L180.944 296.281L182.934 295.509L183.403 293.339Z"
                            fill="#CAE7EF"
                          />
                          <path
                            id="Vector_86"
                            d="M183.933 282.447L185.275 284.148L187.541 283.945L186.345 285.792L187.266 287.858L185.175 287.247L183.523 288.827L183.326 286.573L181.384 285.54L183.464 284.617L183.933 282.447Z"
                            fill="#CAE7EF"
                          />
                          <path
                            id="Vector_87"
                            d="M219.188 273.319L220.651 274.988L222.917 274.786L221.721 276.632L222.642 278.698L220.431 278.118L218.899 279.667L218.702 277.413L216.76 276.38L218.84 275.457L219.188 273.319Z"
                            fill="#CAE7EF"
                          />
                          <path
                            id="Vector_88"
                            d="M210.38 267.661L211.843 269.331L213.957 269.039L212.913 270.974L213.803 272.92L211.623 272.461L209.939 273.921L209.773 271.787L207.921 270.602L209.911 269.831L210.38 267.661Z"
                            fill="#CAE7EF"
                          />
                          <path
                            id="Vector_89"
                            d="M189.492 273.069L190.955 274.739L193.07 274.448L191.904 276.414L192.794 278.36L190.735 277.869L189.052 279.329L188.886 277.196L187.033 276.011L189.023 275.239L189.492 273.069Z"
                            fill="#CAE7EF"
                          />
                        </g>
                      </g>
                      <path
                        id="Vector_90"
                        d="M207.722 316.107L199.457 284.189C199.457 284.189 203.063 272.244 207.772 271.025C212.481 269.806 221.431 278.499 221.431 278.499L229.696 310.417L207.722 316.107Z"
                        fill="#84B4C3"
                      />
                      <path
                        id="Vector_91"
                        d="M229.575 310.449L222.46 282.97C222.46 282.97 226.198 272.528 230.665 271.371C235.132 270.214 243.468 277.531 243.468 277.531L250.583 305.009L229.575 310.449Z"
                        fill="#84B4C3"
                      />
                      <path
                        id="Vector_92"
                        d="M208.194 314.448L203.533 296.449C203.533 296.449 203.657 292.448 204.502 292.23C205.347 292.011 207.397 295.449 207.397 295.449L212.026 313.328L208.194 314.448Z"
                        fill="#9DC7D4"
                      />
                      <path
                        id="Vector_93"
                        d="M213.265 313.135L208.604 295.136C208.604 295.136 208.728 291.135 209.573 290.916C210.418 290.698 212.468 294.136 212.468 294.136L217.097 312.015L213.265 313.135Z"
                        fill="#9DC7D4"
                      />
                      <path
                        id="Vector_94"
                        d="M218.698 311.729L214.037 293.73C214.037 293.73 214.161 289.729 215.006 289.51C215.851 289.291 217.901 292.729 217.901 292.729L222.53 310.608L218.698 311.729Z"
                        fill="#9DC7D4"
                      />
                      <path
                        id="Vector_95"
                        d="M223.769 310.415L219.109 292.417C219.109 292.417 219.232 288.415 220.077 288.197C220.922 287.978 222.972 291.416 222.972 291.416L227.602 309.295L223.769 310.415Z"
                        fill="#9DC7D4"
                      />
                      <path
                        id="Vector_96"
                        d="M229.926 308.821L226.322 294.902C226.322 294.902 226.663 291.741 227.508 291.522C228.354 291.303 230.186 293.901 230.186 293.901L233.759 307.701L229.926 308.821Z"
                        fill="#9DC7D4"
                      />
                      <path
                        id="Vector_97"
                        d="M234.998 307.508L231.394 293.589C231.394 293.589 231.735 290.428 232.58 290.209C233.425 289.99 235.257 292.589 235.257 292.589L238.83 306.388L234.998 307.508Z"
                        fill="#9DC7D4"
                      />
                      <path
                        id="Vector_98"
                        d="M240.189 306.164L236.585 292.245C236.585 292.245 236.926 289.083 237.771 288.865C238.616 288.646 240.449 291.244 240.449 291.244L244.022 305.043L240.189 306.164Z"
                        fill="#9DC7D4"
                      />
                      <path
                        id="Vector_99"
                        d="M245.26 304.851L241.656 290.932C241.656 290.932 241.997 287.77 242.842 287.552C243.688 287.333 245.52 289.931 245.52 289.931L249.093 303.73L245.26 304.851Z"
                        fill="#9DC7D4"
                      />
                      <g id="Group_18">
                        <path
                          id="Vector_100"
                          d="M225.286 267.514C224.699 265.746 224.747 263.941 224.947 262.224C225.205 260.237 226.223 258.693 228.061 257.833C228.272 257.65 228.634 257.557 228.996 257.463C229.993 257.333 230.99 257.203 232.08 257.432C232.988 257.454 233.747 257.897 234.568 258.581C235.541 259.353 236.214 260.459 236.856 261.446C238.478 264.226 238.588 267.143 237.854 270.278C237.589 271.243 237.171 272.119 236.571 272.786C235.492 274.09 234.105 274.705 232.259 274.543C229.565 274.088 227.44 272.846 226.242 270.211C225.873 269.283 225.655 268.443 225.286 267.514ZM231.681 260.865C231.26 261.23 230.991 261.684 230.57 262.049C229.729 262.779 229.584 263.713 229.622 264.855C229.722 266.237 230.306 267.495 230.889 268.752C231.379 269.65 232.259 270.062 233.197 270.203C233.766 269.416 233.821 268.633 233.876 267.851C233.897 266.437 233.917 265.023 233.303 263.646C232.902 262.597 232.654 261.637 231.681 260.865Z"
                          fill="#304B67"
                        />
                        <path
                          id="Vector_101"
                          d="M214.762 276.129C215.421 275.19 215.655 274.105 215.979 272.869C216.186 272.175 216.514 271.45 216.752 270.876C216.99 270.303 217.259 269.849 217.617 269.244C217.396 267.893 217.327 266.63 216.954 265.19C216.651 265.013 216.347 264.835 216.043 264.658C215.623 265.023 215.233 265.508 214.781 265.753C214.329 265.998 213.694 266.034 213.211 266.159C212.908 265.982 212.573 265.684 212.269 265.507C212.176 265.147 211.993 264.938 211.9 264.578C211.5 263.53 211.676 262.716 212.365 261.897C214.735 259.107 219.092 259.515 221.049 262.594C221.936 264.028 222.491 265.677 222.806 267.388C223.12 269.099 222.527 270.789 221.599 272.182C221.42 272.484 221.24 272.787 220.94 273.121C222.089 273.079 223.207 272.918 224.235 272.908C224.476 272.845 224.749 272.903 224.869 272.872C225.656 272.924 226.174 273.43 226.36 274.15C226.546 274.87 226.219 275.595 225.646 275.871C225.194 276.116 224.742 276.361 224.26 276.486C223.535 276.674 222.811 276.862 222.055 276.929C220.696 277.153 219.216 277.408 217.857 277.632C217.495 277.726 217.101 277.7 216.587 277.705C216.073 277.71 215.649 277.564 215.314 277.266C214.921 277.24 214.797 276.76 214.762 276.129Z"
                          fill="#304B67"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              ) : (
                <g id="7">
                  <g id="Group 670">
                    <g id="Frame_7" clip-path="url(#clip6_139_349)">
                      <g id="Group">
                        <g id="Group_2">
                          <g id="Group_3">
                            <path
                              id="Vector_23"
                              d="M246.367 323.802C253.885 323.802 259.98 317.586 259.98 309.918C259.98 302.25 253.885 296.034 246.367 296.034C238.849 296.034 232.754 302.25 232.754 309.918C232.754 317.586 238.849 323.802 246.367 323.802Z"
                              fill="#909299"
                            />
                            <path
                              id="Vector_24"
                              d="M245.479 323.714C253.495 323.714 259.993 317.085 259.993 308.909C259.993 300.732 253.495 294.103 245.479 294.103C237.463 294.103 230.965 300.732 230.965 308.909C230.965 317.085 237.463 323.714 245.479 323.714Z"
                              fill="#AAADBF"
                            />
                            <path
                              id="Vector_25"
                              d="M245.479 322.793C252.997 322.793 259.092 316.577 259.092 308.909C259.092 301.241 252.997 295.025 245.479 295.025C237.961 295.025 231.866 301.241 231.866 308.909C231.866 316.577 237.961 322.793 245.479 322.793Z"
                              fill="url(#paint0_linear_139_349)"
                            />
                            <path
                              id="Vector_26"
                              d="M232.164 309.546C232.164 301.875 238.263 295.662 245.777 295.662C251.137 295.662 255.765 298.819 257.988 303.407C255.901 298.474 251.09 295.025 245.486 295.025C237.971 295.025 231.873 301.238 231.873 308.909C231.873 311.111 232.374 313.198 233.276 315.048C232.564 313.36 232.164 311.497 232.164 309.546Z"
                              fill="#909299"
                            />
                            <path
                              id="Vector_27"
                              d="M245.479 319.432C251.178 319.432 255.799 314.72 255.799 308.909C255.799 303.097 251.178 298.386 245.479 298.386C239.78 298.386 235.159 303.097 235.159 308.909C235.159 314.72 239.78 319.432 245.479 319.432Z"
                              fill="#DDB25B"
                            />
                          </g>
                          <g id="Group_4">
                            <path
                              id="Vector_28"
                              d="M243.263 304.653L242.85 304.403C242.803 304.376 242.789 304.315 242.816 304.274C243.08 303.793 244.503 301.367 246.997 301.15C248.528 301.015 249.477 301.74 250.019 302.417C250.493 303 250.575 303.779 250.554 304.531C250.534 305.446 250.304 306.727 249.247 308.028C247.654 309.986 244.354 312.71 243.616 313.123C243.575 313.144 243.602 313.211 243.65 313.211H248.129C248.129 313.211 248.887 313.211 249.538 311.639C249.558 311.599 249.606 311.572 249.646 311.592L250.012 311.734C250.053 311.755 250.08 311.802 250.06 311.843L248.942 314.871C248.915 314.953 248.833 315.007 248.745 315.007H240.736C240.682 315.007 240.628 314.973 240.607 314.926L240.472 314.614C240.451 314.573 240.465 314.526 240.506 314.499C240.973 314.16 243.941 311.965 246.15 309.505C248.257 307.16 248.223 305.399 248.183 304.999C248.176 304.938 248.169 304.877 248.169 304.823C248.135 304.369 247.858 302.451 245.628 303.034C245.628 303.034 244.327 303.373 243.399 304.613C243.365 304.667 243.304 304.68 243.263 304.653Z"
                              fill="#CC973A"
                            />
                            <g id="Group_5">
                              <path
                                id="Vector_29"
                                d="M241.671 313.625C241.089 314.085 240.655 314.404 240.506 314.512C240.472 314.539 240.458 314.587 240.472 314.627L240.608 314.939C240.628 314.993 240.682 315.02 240.736 315.02H242.383C242.112 314.573 241.875 314.106 241.671 313.625Z"
                                fill="#CC973A"
                              />
                              <g id="Group_6">
                                <path
                                  id="Vector_30"
                                  d="M245.297 303.047C245.297 303.047 244.043 303.379 243.115 304.565L243.264 304.653C243.304 304.68 243.359 304.667 243.393 304.626C244.321 303.386 245.622 303.047 245.622 303.047C245.845 302.993 246.042 302.959 246.232 302.946C245.961 302.925 245.656 302.953 245.297 303.047Z"
                                  fill="#A56B16"
                                />
                                <path
                                  id="Vector_31"
                                  d="M250.019 302.417C249.498 301.767 248.61 301.076 247.201 301.137C248.427 301.191 249.22 301.821 249.701 302.417C250.175 303 250.257 303.779 250.236 304.531C250.216 305.446 249.986 306.727 248.928 308.028C247.336 309.986 244.036 312.71 243.298 313.123C243.257 313.144 243.284 313.211 243.332 313.211H243.65C243.603 313.211 243.575 313.144 243.616 313.123C244.355 312.703 247.655 309.979 249.247 308.028C250.304 306.727 250.534 305.446 250.555 304.531C250.575 303.779 250.494 303.007 250.019 302.417Z"
                                  fill="#A56B16"
                                />
                                <path
                                  id="Vector_32"
                                  d="M250.02 311.741L249.654 311.599C249.613 311.585 249.559 311.605 249.545 311.646C249.539 311.653 249.539 311.666 249.532 311.673L249.701 311.741C249.742 311.761 249.769 311.809 249.749 311.849L248.631 314.878C248.603 314.96 248.522 315.014 248.434 315.014H248.753C248.841 315.014 248.915 314.96 248.949 314.878L250.067 311.849C250.087 311.809 250.06 311.755 250.02 311.741Z"
                                  fill="#A56B16"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g
                    id="Frame_8"
                    clip-path="url(#clip7_139_349)"
                    filter="url(#filter0_d_139_349)">
                    <g id="Group_7">
                      <path
                        id="Vector_33"
                        d="M227.038 221L114 272.276L140.099 329.811L253.137 278.535L227.038 221Z"
                        fill="#D7EDED"
                      />
                      <path
                        id="Vector_34"
                        d="M222.973 222.844L214.845 226.531L240.944 284.066L249.072 280.379L222.973 222.844Z"
                        fill="#93A1A1"
                      />
                      <path
                        id="Vector_35"
                        d="M210.78 228.375L140.037 260.466L166.136 318L236.879 285.909L210.78 228.375Z"
                        fill="#B9CCCC"
                      />
                      <path
                        id="Vector_36"
                        d="M202.906 231.947L180.934 241.914L207.033 299.449L229.005 289.482L202.906 231.947Z"
                        fill="#A5B6B6"
                      />
                      <g id="Group_8">
                        <g id="Group_9">
                          <path
                            id="Vector_37"
                            d="M175.365 248.881L177.245 250.325L179.393 249.658L178.56 251.873L179.863 253.732L177.497 253.58L176.18 255.403L175.512 253.256L173.423 252.365L175.34 251.19L175.365 248.881Z"
                            fill="#EBEBEB"
                          />
                          <path
                            id="Vector_38"
                            d="M193.859 289.651L195.739 291.095L197.887 290.427L197.054 292.643L198.357 294.502L195.991 294.35L194.674 296.173L194.006 294.026L191.917 293.135L193.834 291.959L193.859 289.651Z"
                            fill="#EBEBEB"
                          />
                          <path
                            id="Vector_39"
                            d="M202.649 281.682L204.344 283.057L206.549 282.516L205.659 284.604L207.02 286.591L204.781 286.381L203.336 288.262L202.738 285.93L200.58 285.224L202.439 283.921L202.649 281.682Z"
                            fill="#EBEBEB"
                          />
                          <path
                            id="Vector_40"
                            d="M181.946 291.073L183.769 292.39L185.974 291.849L185.083 293.938L186.444 295.924L184.078 295.772L182.761 297.595L182.035 295.321L180.004 294.557L181.864 293.254L181.946 291.073Z"
                            fill="#EBEBEB"
                          />
                          <path
                            id="Vector_41"
                            d="M171.238 287.048L172.991 288.55L175.266 287.825L174.433 290.04L175.736 291.899L173.37 291.747L171.926 293.628L171.385 291.423L169.169 290.59L171.213 289.357L171.238 287.048Z"
                            fill="#EBEBEB"
                          />
                          <path
                            id="Vector_42"
                            d="M206.928 270.859L208.681 272.361L210.955 271.635L210.123 273.851L211.298 275.768L209.059 275.558L207.615 277.438L207.074 275.233L204.859 274.401L206.776 273.225L206.928 270.859Z"
                            fill="#EBEBEB"
                          />
                          <path
                            id="Vector_43"
                            d="M204.765 260.354L206.461 261.728L208.793 261.13L207.903 263.218L209.263 265.205L206.897 265.053L205.453 266.933L204.855 264.601L202.697 263.895L204.683 262.535L204.765 260.354Z"
                            fill="#EBEBEB"
                          />
                          <path
                            id="Vector_44"
                            d="M163.869 278.905L165.565 280.28L167.897 279.682L167.006 281.77L168.367 283.756L166.001 283.605L164.684 285.427L163.958 283.153L161.8 282.447L163.787 281.087L163.869 278.905Z"
                            fill="#EBEBEB"
                          />
                          <path
                            id="Vector_45"
                            d="M162.366 267.489L164.119 268.991L166.451 268.392L165.56 270.481L166.921 272.467L164.555 272.315L163.111 274.195L162.512 271.863L160.354 271.158L162.341 269.797L162.366 267.489Z"
                            fill="#EBEBEB"
                          />
                          <path
                            id="Vector_46"
                            d="M197.166 251.703L199.046 253.147L201.251 252.606L200.361 254.695L201.721 256.681L199.355 256.529L198.038 258.352L197.313 256.077L195.282 255.314L197.141 254.011L197.166 251.703Z"
                            fill="#EBEBEB"
                          />
                          <path
                            id="Vector_47"
                            d="M187.093 247.39L188.846 248.892L190.994 248.224L190.161 250.439L191.464 252.299L189.225 252.089L187.781 253.969L187.24 251.764L185.024 250.932L186.941 249.756L187.093 247.39Z"
                            fill="#EBEBEB"
                          />
                          <path
                            id="Vector_48"
                            d="M166.39 256.781L168.271 258.225L170.418 257.557L169.585 259.773L170.888 261.632L168.522 261.48L167.205 263.303L166.537 261.155L164.449 260.265L166.366 259.089L166.39 256.781Z"
                            fill="#EBEBEB"
                          />
                        </g>
                      </g>
                      <path
                        id="Vector_49"
                        d="M209.3 232.875L213.097 233.143L235.566 282.677L232.518 284.059L217.48 267.45L206.014 242.176L209.3 232.875Z"
                        fill="#93A1A1"
                      />
                      <path
                        id="Vector_50"
                        d="M209.3 232.875L203.998 237.73L201.806 236.274L183.491 263.419L185.544 265.244L183.777 267.424L184.641 269.33L181.58 272.709L182.905 275.63L184.81 274.766L194.719 296.612L230.663 280.307L224.613 266.971L221.843 267.615L209.063 240.793L210.671 236.235L209.173 232.933L209.3 232.875ZM213.317 276.842L209.621 280.51L204.009 268.813C204.009 268.813 201.313 262.532 203.9 260.134C204.351 259.776 204.674 259.476 205.124 259.119C205.482 259.569 205.839 260.02 206.127 260.655C208.456 265.112 213.317 276.842 213.317 276.842Z"
                        fill="#93A1A1"
                      />
                      <path
                        id="Vector_51"
                        d="M205.124 259.119C204.674 259.476 204.351 259.776 203.9 260.134C201.186 262.59 204.009 268.813 204.009 268.813L209.621 280.51L205.659 284.604L198.019 268.774C198.019 268.774 195.323 262.493 197.91 260.094C200.301 257.938 202.91 256.601 205.124 259.119Z"
                        fill="#5A6960"
                      />
                      <g id="Group_10">
                        <path
                          id="Vector_52"
                          d="M204.416 249.792C203.423 249.63 202.95 248.925 202.605 248.163C202.144 247.147 201.498 246.062 200.783 245.161C199.78 243.625 199.355 241.674 199.298 239.862C199.207 238.985 199.484 238.246 200.189 237.773C201.794 236.586 203.342 235.271 205.293 234.846C206.297 234.696 207.186 234.293 208.133 234.017C209.391 233.752 209.945 233.96 210.406 234.976C210.694 235.612 210.982 236.247 211.27 236.882C211.489 237.701 211.154 238.313 210.334 238.531C209.006 238.981 207.748 239.245 206.363 239.567C205.543 239.786 204.908 240.074 204.088 240.293C204.272 242.047 204.732 243.063 205.747 244.287C207.41 243.227 209.176 242.732 211.116 242.618C215.052 242.517 217.289 244.412 218.279 247.944C218.728 249.272 218.612 250.703 218.126 251.996C217.49 253.969 216.611 255.746 214.694 256.922C213.609 257.567 212.581 258.34 211.138 258.535C208.818 258.821 206.972 258.128 205.53 256.637C205.184 255.875 205.392 255.322 206.039 254.722C206.536 254.803 207.159 254.827 207.655 254.908C209.017 255.209 210.402 254.887 211.672 254.311C212.307 254.023 213.012 253.55 213.289 252.811C213.694 252.015 214.029 251.404 214.364 250.792C214.411 249.546 214.135 248.599 213.293 247.756C212.209 246.716 211.089 246.612 209.762 247.061C209.635 247.118 209.381 247.234 209.254 247.291C207.902 248.364 206.436 249.182 204.416 249.792Z"
                          fill="#5A6960"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              )}
            </g>
          </g>
          <defs>
            <filter
              id="filter0_d_139_349"
              x="110"
              y="221"
              width="147.137"
              height="116.811"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_139_349"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_139_349"
                result="shape"
              />
            </filter>
            <filter
              id="filter1_d_139_349"
              x="104"
              y="220"
              width="128.779"
              height="111.624"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_139_349"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_139_349"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_139_349"
              x1="231.869"
              y1="308.905"
              x2="259.093"
              y2="308.905"
              gradientUnits="userSpaceOnUse">
              <stop offset="0.2947" stop-color="#D3D8DF" />
              <stop offset="0.816" stop-color="#CACFDA" />
              <stop offset="0.878" stop-color="#C8CED9" />
            </linearGradient>
            <clipPath id="clip0_139_349">
              <rect
                width="20.1339"
                height="19.4149"
                fill="white"
                transform="translate(67.2539 201.111)"
              />
            </clipPath>
            <clipPath id="clip1_139_349">
              <rect
                width="20.1339"
                height="19.4149"
                fill="white"
                transform="translate(95 19)"
              />
            </clipPath>
            <clipPath id="clip2_139_349">
              <rect
                width="13.6623"
                height="12.9433"
                fill="white"
                transform="translate(44.2441 246.564) rotate(-29.0034)"
              />
            </clipPath>
            <clipPath id="clip3_139_349">
              <rect
                width="13.6623"
                height="12.9433"
                fill="white"
                transform="translate(304 54.6244) rotate(-29.0034)"
              />
            </clipPath>
            <clipPath id="clip4_139_349">
              <rect
                width="20.1339"
                height="19.4149"
                fill="white"
                transform="translate(297.174 264.521) rotate(39.384)"
              />
            </clipPath>
            <clipPath id="clip5_139_349">
              <rect
                width="37.2645"
                height="35.9336"
                fill="white"
                transform="translate(323.8 153) rotate(39.384)"
              />
            </clipPath>
            <clipPath id="clip6_139_349">
              <rect
                width="29.035"
                height="29.6923"
                fill="white"
                transform="translate(230.965 294.103)"
              />
            </clipPath>
            <clipPath id="clip7_139_349">
              <rect
                width="124.124"
                height="63.1777"
                fill="white"
                transform="translate(114 272.276) rotate(-24.4001)"
              />
            </clipPath>
            <clipPath id="clip8_139_349">
              <rect
                width="106"
                height="58"
                fill="white"
                transform="translate(108 273.579) rotate(-30.362)"
              />
            </clipPath>
            <clipPath id="clip9_139_349">
              <rect
                width="111"
                height="59"
                fill="white"
                transform="translate(143.389 274.636) rotate(-14.5169)"
              />
            </clipPath>
          </defs>
        </svg>
      </Wrapper>
    </div>
  );
};

export default Cestitke;
