import { useEffect, useState, useContext } from "react";
import { VariantContext } from "context/variantContext";
import { useSelector } from "react-redux";

import { Timer } from "../Stopwatch/Timer";
import AnimatedNumber from "animated-number-react";

import imgTime from "assets/images/lov/icon-time.svg";
import imgScore from "assets/images/lov/icon-score.svg";

const CurrentGameStatus = () => {
  const [isAnimating, setIsAnimating] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const variant = useContext(VariantContext);

  const time = useSelector((state) => state.game.time.quiz);
  const score = useSelector((state) => state.game.score);

  const formatValue = (value) =>
    value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  const begin = (value) => {
    setIsAnimating(true);
  };
  const complete = (value) => {
    setIsAnimating(false);
  };

  const longGame = variant === "lov-45" || variant === "45";

  const whenHurry = variant === longGame ? 2400000 : 2400000;

  const hurryUp = time > whenHurry; //840000;

  useEffect(() => {
    if (time === whenHurry) {
      setShowPopup(true);
    }
  }, [time]);

  return (
    <div className="current-game-status rounded-2">
      <div className="d-flex justify-content-between bd-highlight ">
        <div className="text-center mini text-white fw-bold">
          Čas
          <div
            className={`item  d-flex justify-content-center align-items-center shadow-wrapper rounded-2 text-white fw-bold  bd-highlight ${
              hurryUp ? "hurryup" : ""
            }`}>
            <img src={imgTime} className="me-2" />
            <span>
              <Timer time={time} />
            </span>
          </div>
        </div>

        <div className="text-center mini text-white fw-bold">
          Zbrani tolarji
          <div
            className={`item  d-flex justify-content-center align-items-center rounded-2 text-white  bd-highlight ${
              isAnimating && "isanimating"
            } `}
            id="score">
            <img src={imgScore} className="me-2" />
            <span>
              <AnimatedNumber
                value={score}
                formatValue={formatValue}
                begin={begin}
                complete={complete}
                duration={1500}
              />
              &nbsp;SIT
            </span>
          </div>
        </div>
      </div>
      {showPopup && (
        <div className="alert">
          <div className="inside py-5 px-4 position-relative">
            <svg
              onClick={() => setShowPopup(false)}
              className="position-absolute"
              style={{ top: "15px", right: "15px", cursor: "pointer" }}
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0.845456 15.0414L6.73482 9.15205L0.889407 3.30664L3.35063 0.845412L9.19604 6.69082L15.0415 0.845411L17.532 3.33594L11.6866 9.18135L17.532 15.0268L15.0708 17.488L9.22534 11.6426L3.33598 17.5319L0.845456 15.0414Z"
                fill="white"
              />
            </svg>
            <p className="text-white mb-0">
              Blagajna se zapira!
              <br />
              Pohitite in se pravočasno
              <br />
              vrnite na zbirno mesto!
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default CurrentGameStatus;
