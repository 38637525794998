import { motion } from "framer-motion";

import { fadeInOut } from "utils/animations";

const FixedTopBar = () => {
  const NameDisplay = () => {
    return <div>HEADER</div>;
  };

  return (
    <motion.div
      variants={fadeInOut}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="fixed-top-bar d-flex align-items-center d-none">
      <div className="w-100 text-center">
        <NameDisplay />
      </div>
    </motion.div>
  );
};

export default FixedTopBar;
