import { useState } from "react";
import ReactCardFlip from "react-card-flip";
import { motion } from "framer-motion";
import { staggerItemsFade, itemFade } from "utils/animations";
import { RiCheckFill, RiCloseFill } from "react-icons/ri";

import imgCardBack from "assets/images/lov/card_back.svg";
import imgCardFront from "assets/images/lov/card_front.svg";

const QuizAnswers = ({
  gamesData,
  data,
  clickHandler,
  isFormSubmitted,
  isFlipped,
  isAnswerCorrect,
  answerClicked,
}) => {
  const clickedIndex = answerClicked
    ? parseInt(answerClicked.split("_")[1])
    : null;

  return (
    <>
      <motion.div
        className={`answer-section row  d-flex justify-content-center px-4 ${
          data.answerIsImg ? "answer-is-image" : "answer-is-text"
        } ${answerClicked ? "clicked" : ""} `}
        variants={staggerItemsFade}
        initial="hidden"
        animate="show"
        exit="hidden">
        {data.answers.map((item, index) => (
          <motion.button
            key={index}
            type={`${gamesData.id === "quizbonus" ? "submit" : "button"}`}
            className={`answ-container col-6 btn btn-answer py-0 px-0 `}
            style={{
              pointerEvents: isFormSubmitted | !isFlipped ? "none" : "auto",
            }}
            size="lg"
            id={"answer_" + index}
            onClick={(e) => clickHandler(e, item.isCorrect)}>
            <ReactCardFlip
              isFlipped={isFlipped}
              flipDirection="horizontal"
              flipSpeedBackToFront={0.7}
              flipSpeedFrontToBack={0.7}>
              <div>
                <img className="w-100 p-1" src={imgCardBack} />
              </div>

              <div>
                <img className="w-100 p-1" src={imgCardFront} />
                {item.isCorrect && (
                  <span
                    style={{ fontSize: "10px", top: "0", left: "0" }}
                    className="position-absolute d-none">
                    C
                  </span>
                )}
                <p>{item.answer}</p>
              </div>
            </ReactCardFlip>

            <span className="animation animation__active"></span>
            {isFormSubmitted && (
              <>
                <motion.span
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: isAnswerCorrect ? 0 : 0.5 }}
                  className="animation animation__correct">
                  <motion.span
                    initial={{ opacity: 0, scale: 5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.5 }}>
                    <svg
                      width="90"
                      height="90"
                      viewBox="0 0 90 90"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <rect
                        x="13.3677"
                        y="44.5605"
                        width="44.5959"
                        height="18.9048"
                        transform="rotate(45 13.3677 44.5605)"
                        fill="#018460"
                      />
                      <rect
                        x="89.4639"
                        y="31.5356"
                        width="80.9512"
                        height="19.8742"
                        transform="rotate(135 89.4639 31.5356)"
                        fill="#018460"
                      />
                    </svg>
                  </motion.span>
                </motion.span>

                <motion.span
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0 }}
                  className="animation animation__wrong">
                  <motion.span
                    initial={{ opacity: 0, scale: 5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.5 }}>
                    <svg
                      width="72"
                      height="73"
                      viewBox="0 0 72 73"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <rect
                        x="71.2959"
                        y="14.0532"
                        width="80.9512"
                        height="19.8742"
                        transform="rotate(135 71.2959 14.0532)"
                        fill="#C15348"
                      />
                      <rect
                        x="57.2412"
                        y="72.2942"
                        width="80.9512"
                        height="19.8742"
                        transform="rotate(-135 57.2412 72.2942)"
                        fill="#C15348"
                      />
                    </svg>
                  </motion.span>
                </motion.span>
              </>
            )}
          </motion.button>
        ))}
      </motion.div>{" "}
    </>
  );
};

export default QuizAnswers;
