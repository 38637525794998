import { createElement, useState } from "react";
import { useDispatch } from "react-redux";
import { showIntroReducer } from "store/gameSlice";

import CurrentGameStatus from "components/CurrentGameStatus/CurrentGameStatus";

import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";

import imgFigure from "assets/images/figure-banka-questions2.svg";

const GameQuizIntro = ({ gamesData }) => {
  const [inputValue, setInputValue] = useState("");
  const [isCorrect, setIsCorrect] = useState(null);
  const dispatch = useDispatch();

  // Enable or disable the button based on input value
  const isButtonEnabled = inputValue.trim() !== "";

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setIsCorrect(null);
  };

  const handleClick = () => {
    const normalizedInput = inputValue.toLowerCase();

    if (
      gamesData.IntroTextAnswer.some(
        (answer) => answer.toLowerCase() === normalizedInput
      )
    ) {
      dispatch(showIntroReducer(false));
      setIsCorrect(true);
    } else {
      setIsCorrect(false);
    }
  };

  return (
    <Wrapper
      className={`game ${gamesData.id} intro shadow-wrapper bg-white m-2 w-100 position-relative `}>
      <div className="flex-grow-1 p-2 text-center">
        <CurrentGameStatus />

        <h2 className="text-gold mt-3 mb-2">{gamesData.headline}</h2>

        <div className="px-3 text-center">
          <p>{gamesData.introText1}</p>
          <img
            className="w-75"
            src={`/images/lov/intro_${gamesData.id}.jpg`}
            alt=""
          />
          <h3 style={{ fontSize: "20px" }} className="text-gold mt-3">
            Ste na pravi lokaciji?
          </h3>
          <p>{gamesData.introText2}</p>

          <div
            className={`w-100 text-center ${
              isCorrect === false ? "danger" : null
            }`}>
            <input
              className="answer mb-0 form-control text-center"
              type="text"
              name="title"
              placeholder="Vpiši geslo"
              value={inputValue}
              onChange={handleInputChange}
            />
            {isCorrect === false && (
              <p className="text-red fw-bold mb-0 danger">
                Ta rešitev ni pravilna.
              </p>
            )}

            <Button
              label="NAPREJ"
              className={`w-75 text-center mx-auto mb-0 mt-0 ${
                !isButtonEnabled ? "disabled" : ""
              }`}
              onClick={handleClick}
              disabled={!isButtonEnabled}
            />
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default GameQuizIntro;
