import { useTranslation } from "react-i18next";

const QuizQuestion = ({ data, gamesData }) => {
  const { t } = useTranslation();

  return (
    data.questionText !== null && (
      <div className="question-section mb-4 bg-gold-medium rounded-2 w-100">
        <div
          className="description p-3"
          dangerouslySetInnerHTML={{ __html: t(data.questionText) }}
        />
      </div>
    )
  );
};

export default QuizQuestion;
