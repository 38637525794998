import { useContext } from "react";
import { VariantContext } from "context/variantContext";
import { useTranslation, Trans } from "react-i18next";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";

import Wrapper from "components/Wrapper/Wrapper";

import imgLogoBs from "assets/images/lov/logobs.svg";
import imgLogoEb from "assets/images/lov/escapebox.svg";
import imgLogobrainy from "assets/images/lov/brainy.svg";
import imgFoot from "assets/images/lov/foot.svg";

const Logo = ({ step, setStep }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const variant = useContext(VariantContext);

  const goToStory = () => {
    setTimeout(() => {
      navigate(`/${variant}/intro/story1`);
    }, 1000);
  };

  const logoAnimation = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delay: 0.5,
        duration: 2,
      },
    },
    exit: {
      opacity: 0,
    },
  };

  return (
    <div className="start-container intro logo theme-dark">
      <Wrapper className="start w-100 text-center">
        <div className="flex-grow-1">
          <motion.div
            className="h-100 position-relative d-flex align-items-center justify-content-center"
            variants={logoAnimation}
            initial="hidden"
            animate="show"
            onAnimationComplete={() => goToStory()}>
            <img src={imgLogoBs} />
            <img
              className="position-absolute"
              style={{ bottom: "10px", left: "10px" }}
              src={imgLogoEb}
            />

            <img
              className="position-absolute"
              style={{ bottom: "10px", right: "10px" }}
              src={imgLogobrainy}
            />

            <img
              src={imgFoot}
              className="position-absolute"
              style={{ bottom: "0", right: "0" }}
            />
          </motion.div>
        </div>
      </Wrapper>
    </div>
  );
};

export default Logo;
