import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentQuestion: 0,
  correctAnswers: [],
  correctAnswersTotal: {
    game1: false,
    game2: false,
    game3: false,
    game4: false,
    game5: false,
    game6: false,
    game7: false,
  },

  score: 0,
  time: { quiz: 0 },
  startTime: null,
  isStartTimeInitialized: false,
  showIntro: true,
  showResult: false,
  showTrivia: false,
  showMilestone: false,
};

export const gameSlice = createSlice({
  name: "game",
  initialState,
  reducers: {
    currentQuestionReducer: (state, action) => {
      state.currentQuestion = action.payload;
    },

    correctAnswersReducer: (state, action) => {
      state.correctAnswers.push(action.payload);
    },

    correctAnswersTotalReducer: (state, action) => {
      const { gameKey, value } = action.payload;

      if (gameKey && state.correctAnswersTotal.hasOwnProperty(gameKey)) {
        state.correctAnswersTotal[gameKey] = value;
      }
    },

    scoreReducer: (state, action) => {
      state.score += action.payload;
    },

    setStartTime: (state, action) => {
      state.startTime = action.payload; // Set the start time to the payload value
    },

    initializeStartTime: (state) => {
      if (!state.isStartTimeInitialized) {
        state.startTime = Date.now();
        state.isStartTimeInitialized = true; // Set the flag to true
      }
    },

    timeReducer: (state, action) => {
      if (state.startTime) {
        const currentTime = action.payload;
        state.time.quiz = currentTime - state.startTime; // Calculate the time elapsed
      }
    },

    showIntroReducer: (state, action) => {
      state.showIntro = action.payload;
    },

    showResultReducer: (state, action) => {
      state.showResult = action.payload;
    },

    showTriviaReducer: (state, action) => {
      state.showTrivia = action.payload;
    },

    showMilestoneReducer: (state, action) => {
      state.showMilestone = action.payload;
    },

    reset: (state) => ({
      ...initialState,
      isStartTimeInitialized: state.isStartTimeInitialized,
      startTime: state.startTime,
      time: state.time,
      score: state.score,
      correctAnswersTotal: state.correctAnswersTotal,
    }),
    resetAll: () => ({
      ...initialState,
    }),
  },
});

export const {
  currentQuestionReducer,
  correctAnswersReducer,
  correctAnswersTotalReducer,
  scoreReducer,
  initializeStartTime,
  setStartTime,
  timeReducer,
  showIntroReducer,
  showResultReducer,
  showTriviaReducer,
  showMilestoneReducer,
  reset,
  resetAll,
} = gameSlice.actions;

export default gameSlice.reducer;
